<template>
  <section>
    <b-form>
      <section>
        <div class="grid grid-cols-1 md:grid-cols-12">
          <div class="col-span-8 pb-2">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 lg:col-span-6">
                <input-default :label="$t('sectionUsers.user')" />
              </div>
              <div class="col-span-12 lg:col-span-6">
                <input-default :label="$t('sectionUsers.fullUsername')" />
              </div>
            </div>
          </div>

          <div class="col-span-8 pb-2">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12">
                <input-default :label="$t('sectionUsers.email')" />
              </div>
            </div>
          </div>

          <div class="col-span-8 border-solid border-b border-gray-300 pb-3">
            <div
              class="w-full flex flex-col sm:flex-row items-start gap-6 sm:gap-4"
            >
              <SelectDropdown
                :dropDownTextPrimary="$t('sectionUsers.userLevel1')"
                :dropDownTextSecondary="$t('sectionUsers.userLevel2')"
                :textSelected.sync="userLevel"
              />
              <SelectDropdown
                :dropDownTextPrimary="$t('sectionUsers.userStatus1')"
                :dropDownTextSecondary="$t('sectionUsers.userStatus2')"
                :textSelected.sync="userStatus"
              />
            </div>
          </div>

          <div
            class="col-span-8 border-solid border-b border-gray-300 pb-3 mt-5"
          >
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 lg:col-span-6">
                <input-default :label="$t('sectionUsers.password')" />
              </div>
              <div class="col-span-12 lg:col-span-6">
                <input-default :label="$t('sectionUsers.confirmPassword')" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="w-full flex items-center justify-start gap-5 my-10">
        <Button
          class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
          buttonText="Confirmar"
          :onClick="openModal"
        />
        <Button
          class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
          buttonText="Cancelar"
          :onClick="redirectUrl"
        />
        <Modal
          v-if="modalIsOpen"
          :modalIsOpen.sync="modalIsOpen"
          :closeModal="closeModal"
          :modalText="$t('commons.modalTextCreate')"
          modalFeaturedText="sucesso!"
        />
      </footer>
    </b-form>
  </section>
</template>

<script>
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";
import SelectDropdown from "@core/components/commons/selectDropdown/SelectDropdown";
import InputDefault from "@core/components/commons/inputs/InputDefault";

import { BForm } from "bootstrap-vue";

export default {
  name: "ManageUsersCreate",
  components: {
    BForm,
    Button,
    Modal,
    SelectDropdown,
    InputDefault,
  },
  data() {
    return {
      modalIsOpen: false,
      userLevel: "Administrador",
      userStatus: "Ativo",
    };
  },
  methods: {
    redirectUrl() {
      return this.$router.push("/users");
    },
    openModal() {
      this.modalIsOpen = true;
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style></style>
