<template>
  <main class="w-full flex flex-col gap-1">
    <div>
      <button
        class="background__button w-full h-14 flex items-center justify-center rounded font-bold text-black border__bottom"
        @click.prevent="statusActive = !statusActive"
      >
        {{ dropDownActive ? dropDownTextPrimary : dropDownTextSecondary }}
      </button>
    </div>
    <div v-if="statusActive">
      <button
        class="background__button w-full h-14 flex items-center justify-center rounded font-bold text-black"
        @click.prevent="onChangeDropDown(), onClickDropDownText()"
      >
        {{ !dropDownActive ? dropDownTextPrimary : dropDownTextSecondary }}
      </button>
    </div>
  </main>
</template>

<script>
export default {
  name: "SelectDropdown",
  props: {
    dropDownTextPrimary: {
      type: String,
      required: true,
    },
    dropDownTextSecondary: {
      type: String,
      required: true,
    },
    textSelected: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dropDownActive: true,
      statusActive: false,
      dropDownTextSelected: this.textSelected,
    };
  },
  methods: {
    onClickDropDownText() {
      this.dropDownActive = !this.dropDownActive;

      if (this.dropDownActive) {
        this.dropDownTextSelected = this.dropDownTextPrimary;
      } else {
        this.dropDownTextSelected = this.dropDownTextSecondary;
      }
    },
    onChangeDropDown() {
      this.statusActive = !this.statusActive;
    },
  },
  watch: {
    dropDownTextSelected() {
      this.$emit("update:textSelected", this.dropDownTextSelected);
    },
  },
};
</script>

<style scoped>
.background__button {
  background: #efefef;
}

.border__bottom {
  border-bottom: 3px solid black;
}
</style>
